// sagas.js
import {put, call, takeLatest, all, delay} from 'redux-saga/effects';
import {
    FETCH_DATA_REQUEST_SAGA,
    FETCH_DATA_FAILURE_SAGA,
    FETCH_DATA_SUCCESS_SAGA,
    FETCH_TABS_REQUEST_SAGA,
    FETCH_TABS_SUCCESS_SAGA,
    FETCH_TABS_FAILURE_SAGA,
    SET_LOADING,
    SET_TAB_REQUEST_SAGA,
    SET_TAB_SUCCESS_SAGA,
    SET_TAB_FAILURE_SAGA,
    STOP_LOADING,
    SET_DATA_MUIDATAGRID_REQUEST_SAGA, SET_DATA_MUIDATAGRID_SUCCESS_SAGA, SET_DATA_MUIDATAGRID_FAILURE_SAGA,
} from "../actions/actionTypes";
import axios from "../../api/axios-peer";
import {
    getGridBooleanOperators, getGridDateOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators
} from "@mui/x-data-grid-premium";
import CustomSingleSelectFilter from "../../components/DataTable/Filters/CustomSingleSelectFilter";
import CustomColorSelectFilter from "../../components/DataTable/Filters/CustomColorSelectFilter";
import {
    getNestedValues,
    handleFilterField, setAggregableFields,
    setCustomColorSelectFilter, setCustomEditFields,
    setFilterOperators, setGroupOnColumn, setPercentageFields, setRenderFields,
    setSingleSelectFilterOperators
} from "./datatableFunctions";

function* fetchDataSaga(action) {
    try {
        const {
            url,
            index,
            numberOfRow,
            page,
            sortOrder,
            urlAddition,
            searchParams,
            activeTabFilter,
            resource,
            callback,
            routeFilter
        } = action;

        yield put({type: SET_LOADING, index});

        // Construct the URL with the query parameters
        let fullUrl = url;

        let questionmarked = url.includes("?");

        if (numberOfRow !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "per-page=" + numberOfRow;
        }

        if (page !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "page=" + (page + 1);
        }

        if (urlAddition != null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += urlAddition;
        }

        if (searchParams != null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += searchParams;
        }

        if (routeFilter != null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += routeFilter;
        }

        if (activeTabFilter) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }

            fullUrl += activeTabFilter;
        }

        if (sortOrder != null) {
            if (questionmarked === false) {
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "sort=" + sortOrder;
        }

        // Use the provided resource or the default axios
        const rest = resource || axios;

        // Make the API request using the provided HTTP client library
        const response = yield call(rest.get, fullUrl, {responseType: 'json'});

        // If a callback is provided, invoke it with the response data
        if (callback) {
            callback(response.data);
        }
        // Dispatch the success action with the response data
        yield put({type: FETCH_DATA_SUCCESS_SAGA, index, data: response.data});
        yield put({type: SET_DATA_MUIDATAGRID_REQUEST_SAGA, index, data: response.data?.columns || [], tableFilters: response.data?.filters || []});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: FETCH_DATA_FAILURE_SAGA, index: action.index, error: action.index + ': ' + error.message});
    } finally {
        // yield put({type: STOP_LOADING, index: action.index});
    }
}

function* fetchTabsSaga(action) {
    try {
        const {
            url,
            index,
            resource
        } = action;

        let fullUrl = url;

        fullUrl += fullUrl.endsWith('/') ? "tabs" : "/tabs"

        // Use the provided resource or the default axios
        const rest = resource || axios;

        // Make the API request using the provided HTTP client library
        const response = yield call(rest.get, fullUrl, {responseType: 'json'});
        // Dispatch the success action with the response data
        yield put({type: FETCH_TABS_SUCCESS_SAGA, index, data: response.data});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: FETCH_TABS_FAILURE_SAGA, index: action.index, error: error.message});
    }
}

function* setTabsSaga(action) {
    try {
        const {
            index,
            tab,
            currentTab
        } = action;
        yield put({type: SET_TAB_SUCCESS_SAGA, index, tab, currentTab});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: SET_TAB_FAILURE_SAGA, index: action.index, error: `${error.message} - cannot set tab`});
    }
}

// Helper function to process each column
function* processColumn(item, columnIndex, index, tableFilters) {
    if (!item.field) return item;

    let newItem = { ...item };

    yield all([
        call(setFilterOperators, newItem),
        // call(handleFilterField, newItem),
        call(setSingleSelectFilterOperators, newItem, columnIndex, tableFilters),
        call(setCustomColorSelectFilter, newItem, index),
        call(getNestedValues, newItem),
        call(setGroupOnColumn, newItem, tableFilters),
        call(setAggregableFields, newItem),
        call(setPercentageFields, newItem),
        call(setRenderFields, newItem),
    ]);

    return newItem;
}

//Update certain custom column info for Mui Data Grid
function* setDataMuiDataGrid(action) {
    try {
        const { index, data, tableFilters } = action;

        const chunkSize = 100;
        let newColumns = [];

        for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            const processedChunk = yield all(
                chunk.map((item, chunkIndex) =>
                    call(processColumn, item, i + chunkIndex, index, tableFilters)
                )
            );
            newColumns = [...newColumns, ...processedChunk];

            yield delay(0); // `delay(0)` allows other sagas to run in between chunks
        }
        yield put({type: SET_DATA_MUIDATAGRID_SUCCESS_SAGA, index, newColumns});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: SET_DATA_MUIDATAGRID_FAILURE_SAGA, index: action.index, error: `${error.message} - cannot set data correctly for mui`});
    } finally {
        yield put({type: STOP_LOADING, index: action.index});
    }
}

// Watch for FETCH_TABS_REQUEST and run fetchTabsSaga on latest occurrence
export function* watchFetchTabs() {
    yield takeLatest(FETCH_TABS_REQUEST_SAGA, fetchTabsSaga);
}

// Watch for FETCH_DATA_REQUEST and run fetchDataSaga on latest occurrence
export function* watchFetchData() {
    yield takeLatest(FETCH_DATA_REQUEST_SAGA, fetchDataSaga);
}

// Watch for FETCH_DATA_REQUEST and run fetchDataSaga on latest occurrence
export function* watchSetTab() {
    yield takeLatest(SET_TAB_REQUEST_SAGA, setTabsSaga);
}

// Watch for FETCH_DATA_SUCCESS and set data for MuiDatagrid
export function* watchFetchDataComplete() {
    yield takeLatest(SET_DATA_MUIDATAGRID_REQUEST_SAGA, setDataMuiDataGrid);
}