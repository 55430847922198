import Grid from "@mui/material/Grid2";
import React, {useState} from "react";
import customClasses from "./TemplateForm.module.css";
import SearchIcon from "../../Controls/SearchIcon";
import PropTypes from "prop-types";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import SimpleDialog from "../../Dialog/SimpleDialog";
import SearchLocationAdressDialog from "../Templates/DialogDetails/SearchLocationAdressDialog";

export default function TemplateFormOrigin(props) {

    const [openDialog, setOpenDialog] = useState(false);
    const group = "locationorigin";

    const {
        formField: {
            locationorigin_description,
            locationoriginaddress_street_name,
            locationoriginaddress_house_no,
            locationoriginaddress_house_no_addition,
            locationoriginaddress_postal_code,
            locationoriginaddress_city_name,
        }
    } = props;

    // =====================
    // Handle Open and Close dialogs
    // =====================
    const openModal = () => {
        setOpenDialog(true);
    }
    const closeModal = () => {
        setOpenDialog(false);
    };

    // ==========================================
    // Handle Selected Eural
    // ==========================================
    const handleLocationSelected = (selectedLocation) => {
        props.setFieldValue(group + '.description', selectedLocation.name || '');
        props.setFieldValue(group + '.address_street_name', selectedLocation.street_name || '');
        props.setFieldValue(group + '.address_house_no', selectedLocation.building_number || '');
        props.setFieldValue(group + '.address_house_no_addition', selectedLocation.building_number_addition || '');
        props.setFieldValue(group + '.address_postal_code', selectedLocation.postal_code || '');
        props.setFieldValue(group + '.address_city_name', selectedLocation.city_name || '');
        closeModal();
    };

    // Search Location dialog
    const dialog = () => {
        if (!props.data) {
            return;
        }
        return (
            <SimpleDialog
                title="Zoek locatie"
                fields={props}
                size="lg"
                fullWidth={true}
                open={openDialog}
                handleClose={closeModal}>
                <SearchLocationAdressDialog
                    locationType="origin"
                    companyId={props.disabled ? null : props.data?.project?.company?.id || null}
                    handleLocationSelected={handleLocationSelected}
                />
            </SimpleDialog>
        )
    }

    return <React.Fragment>
        {dialog()}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDivider} container size={12}/>
        <div className={customClasses.blockIdentifier}><strong>3<span className={customClasses.blockPart}>B</span></strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        <Grid container size={12}>
            <Grid size={{xs:11, md:3}}>
                {locationorigin_description.label}
            </Grid>
            <Grid size={1}>
                <SearchIcon
                    color="rgb(113, 43, 1)"
                    handleClick={openModal}
                    disabled={props.disabled}
                />
            </Grid>
            <Grid size={{xs:11, md:8}}>
                <FastField
                    autoComplete="new-password"
                    sizeClass={customClasses.inputFull}
                    name={group + "." + locationorigin_description.name}
                    disabled={props.disabled}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs:11, md:3}}>
                {locationoriginaddress_street_name.label}
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs:12, md:8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_street_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputMedium}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_house_no.name}
                    type='number'
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_house_no_addition.name}
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12} >
            <Grid container size={{xs:11, md:3}} className={customClasses.noWordBreak}>
                <Grid size={{xs:6, md:6}} className={customClasses.container}>
                    {locationoriginaddress_postal_code.label}
                </Grid>
                <Grid size={{xs:6, md:6}} className={customClasses.containerRequired}>
                    {locationoriginaddress_city_name.label}
                </Grid>
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs:12, md:8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_postal_code.name}
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_city_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputLarge}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid className={`${customClasses.container} ${customClasses.subContainer}`} container size={12}>
            <Grid size={{xs:12, md:4}}>
                datum aanvang transport
            </Grid>
            <Grid size={{xs:12, md:8}}>
                -
            </Grid>
        </Grid>
    </React.Fragment>
}

TemplateFormOrigin.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    data: PropTypes.object,
    project: PropTypes.object,
};