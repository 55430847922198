import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {FixedSizeList as List} from "react-window";
import Grid from "@mui/material/Grid2";
import BusinessIcon from '@mui/icons-material/Business';
import SearchBar from "../../../Controls/SearchBar";
import PropTypes from "prop-types";
import axios from "../../../../api/axios-peer";
import {fetchDataFail} from "../../../../store/actions/dataTable";
import {useDispatch} from "react-redux";
import {Typography} from "@mui/material";

const PREFIX = 'SearchInvoiceAddressDialog';

const classes = {
    rowEven: `${PREFIX}-rowEven`,
    rowOdd: `${PREFIX}-rowOdd`,
    emptyContainer: `${PREFIX}-emptyContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    [`& .${classes.rowEven}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        },
    },

    [`& .${classes.rowOdd}`]: {
        background: "#FFFFFF",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        }
    },

    [`& .${classes.emptyContainer}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '100px',
        marginTop: '20px',
    }
}));

export default function SearchInvoiceAddressDialog(props) {


    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState();
    const [emptylist, setEmptyList] = useState(false);

    // =====================
    // Handle Selected Company
    // =====================
    const onItemClickHandler = (selected) => {
        props.handleSelected(selected)
        setFilteredData(data);
    }

    // ======================
    // Get Data
    // ======================
    useEffect(() => {
        let isMounted = true;
        let companyFilter = '';
        if (props.companyId) {
            companyFilter = '&company_id=' + props.companyId;
        }
        axios({
            url: 'template-address?type=invoice' + companyFilter,
        }).then(response => {
            if (isMounted) setData(response.data.items);
        }).catch(error => {
            // console.log(error)
            dispatch(fetchDataFail(error.message))
        })
        return () => {
            isMounted = false
        };
    }, [])

    // =====================
    // Handle Search
    // =====================
    const handleChange = (searchInput) => {
        setSearchInput(searchInput);
        const result = data?.filter((item) => {
            if (item.street_name === null || item.description === null) {
                return
            }
            return item.street_name.toLocaleLowerCase().includes(searchInput) || item.description.toLocaleLowerCase().includes(searchInput);
        });
        setFilteredData(result);
    }

    const clearFilter = () => {
        setFilteredData(data);
        setEmptyList(false)
    }

    // =====================
    // Check if list is empty
    // =====================
    useEffect(() => {
        if (filteredData === undefined || filteredData === null) {
            return
        }
        if (filteredData.length === 0 && searchInput.length > 0) {
            setEmptyList(true);
        } else {
            setEmptyList(false);
        }
    }, [searchInput])

    // =====================
    // Filtered array
    // =====================
    useEffect(() => {
        if (data === undefined || data === null) {
            return
        }
        if (data.length === 0) {
            setEmptyList(true);
        }
        setFilteredData(data)
    }, [data])


    // =====================
    // Company rows
    // =====================

    const list = filteredData;

    const renderRow = ({index, style}) => (
        <div>
            <div key={list[index].id} style={style} className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                 onClick={onItemClickHandler.bind(this, list[index])}>
                <Grid container>
                    <Grid size={2}>
                        <BusinessIcon/>
                    </Grid>
                    <Grid size={{xs: 10, md: 5}}>
                        <Typography variant="body2"><strong>{list[index].description}</strong></Typography>
                        <Typography variant="body2">{list[index].street_name} {list[index].house_no}{list[index].house_no_addition}</Typography>
                        <Typography variant="body2">{list[index].postal_code}, {list[index].city_name}</Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

    return (
        <Root>
            <Grid container>
                <Grid size={12}>
                    <SearchBar
                        handleChange={handleChange}
                        clearFilter={clearFilter}
                    />
                </Grid>
            </Grid>
            {
                emptylist ? (
                    <div>
                        <div className={classes.emptyContainer}>
                            <span>Geen Locaties gevonden</span><br/>
                        </div>
                    </div>
                ) : (
                    <List
                        height={700}
                        itemCount={list ? list.length : null}
                        itemSize={100}
                    >
                        {renderRow}
                    </List>
                )
            }
            <br/>
        </Root>
    );
}

SearchInvoiceAddressDialog.propTypes = {
    handleSelected: PropTypes.func.isRequired,
    data: PropTypes.array,
    companyId: PropTypes.number
};