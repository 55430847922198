// sagas.js
import {fork } from 'redux-saga/effects';
import {watchFetchData, watchFetchDataComplete, watchFetchTabs, watchSetTab} from './datatable';
import {watchGpsData} from "./gps"; // Import your fetchDataSaga here

// Combine all your sagas here
export default function* rootSaga() {
    yield fork(watchFetchTabs)
    yield fork(watchFetchData)
    yield fork(watchSetTab)
    yield fork(watchGpsData)
    yield fork(watchFetchDataComplete)
}