import React from "react";
import {Button, Typography} from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: '',
            errorInfo: '',
        };
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error: error});
        this.setState({errorInfo: errorInfo});
    }


    render() {
        if (this.state.hasError) {
            return (
                <Grid container spacing={2} alignItems="flex-end" sx={{p: 10}}>
                    <Grid size={12}>
                        <Typography variant="h4" color='error'>Error</Typography>
                        <Typography variant="h5" color='error' sx={{fontSize: "20px"}}>Er is iets fout gegaan. Mocht dit
                            probleem blijven optreden neem contact op met Scoretrace.</Typography>
                        <Typography variant="h5" color='error'
                                    sx={{
                                        fontSize: "18px",
                                        marginTop: '5px',
                                        display: 'flex',
                                        alignContent: 'center'
                                    }}>
                            <EmailIcon sx={{marginRight: '5px'}}/> info@scoretrace.com</Typography>
                        <Typography variant="h5" color='error'
                                    sx={{
                                        fontSize: "18px",
                                        marginTop: '5px',
                                        display: 'flex',
                                        alignContent: 'center'
                                    }}><LocalPhoneIcon
                            sx={{marginRight: '5px'}}/>+31(0) 172 43 63 73 </Typography>
                    </Grid>
                    <Grid size={12}>
                        {
                            process.env.REACT_APP_ENV !== 'Production' ? (
                                <Typography variant="body2" color='error'>{this.state.error?.stack}</Typography>
                            ) : ('')
                        }
                        <br/>
                        <Button sx={{backgroundColor: '#EE6E2C'}} variant="contained" type="button"
                                onClick={() => this.setState({hasError: false})}>
                            Terug
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element.isRequired,
};