import React, {Component} from "react";
import {connect} from 'react-redux';
import {Navigate} from "react-router-dom";
import {Link} from 'react-router-dom';
import customClasses from './Auth.module.css';
import * as actions from '../../store/actions/auth'
import {Button, Alert} from '@mui/material';
import {compose} from "redux";
import Logo from "../../components/Logo/Logo";
import LoginEmail from "./Login/LoginEmail";
import LoginPassword from "./Login/LoginPassword";
import Typography from "@mui/material/Typography";
// import LanguageSelector from "../../components/Inputs/LanguageSelector";
import i18n from "../../i18n/i18n";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";

class Auth extends Component {
    state = {
        email: '',
        emailError: null,
        password: '',
        // rememberMe: false,
        showPassword: false,
        authenticate: false,
        dialogForgotPassword: false,
    }

    componentDidMount() {
        if (!this.state.isAuthenticated) {
            this.props.onCheckAuthenticated()
        }
    }

    handleChange = (prop) => (event) => {
        this.setState({...this.state, [prop]: event.target.value});
    };

    handleClickShowPassword = () => {
        this.setState({...this.state, showPassword: !this.state.showPassword});
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    toggleLoginAccount = () => {
        if (this.state.email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.email.toLowerCase())) {
                this.setState({...this.state, emailError: i18n.t('EMAIL_IS_NOT_VALID')});
                return;
            }
            this.setState({...this.state, emailError: null, password: null, authenticate: !this.state.authenticate});
        } else {
            this.setState({...this.state, emailError: i18n.t('FIELD_CANNOT_BE_EMPTY')});
        }
    }

    // toggleRememberMe = () => {
    //     this.setState({ ...this.state, rememberMe: !this.state.rememberMe });
    // }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.email, this.state.password)
    }

    dialogForgotPassword = () => {
        this.setState({...this.state, dialogForgotPassword: !this.state.dialogForgotPassword});
    }
    closeDialogForgotPassword = () => {
        this.setState({...this.state, dialogForgotPassword: !this.state.dialogForgotPassword});
    };

    render() {
        let form =
            <LoginEmail
                email={this.state.email}
                emailError={this.state.emailError}
                // rememberMe={this.state.rememberMe}
                handleEmailChange={this.handleChange('email')}
                toggleLoginAccount={this.toggleLoginAccount}
                // rememberMeToggle={this.toggleRememberMe}
            />

        if (this.state.authenticate) {
            form =
                <LoginPassword
                    email={this.state.email}
                    password={this.state.password ? this.state.password : ''}
                    showPassword={this.state.showPassword}
                    handlePasswordChange={this.handleChange('password')}
                    handleClickShowPassword={this.handleClickShowPassword}
                    handleMouseDownPassword={this.handleMouseDownPassword}
                    toggleLoginAccount={this.toggleLoginAccount}
                    loading={this.props.loading}
                    handleSubmit={this.submitHandler}
                />
        }
        let forgotPassword =
            <Grid container alignItems="flex-end" justifyContent="flex-end">
                <Link style={{textDecoration: 'none'}} to='/reset-password'>
                    <Button
                        disableFocusRipple
                        disableRipple style={{textTransform: "none"}}
                        variant="text"
                        color="primary">
                        {i18n.t('BUTTON_FORGOT_PASSWORD')}?
                    </Button>
                </Link>
            </Grid>

        let errorMessage = null;
        if (this.props.error) {
            errorMessage = <Alert severity="error">{this.props.error}</Alert>
        }
        let authRedirect = null;
        if (this.props.isAuthenticated) {
            authRedirect = <Navigate to={this.props.authNavigatePath}/>
        }

        return <div className={customClasses.AuthData}>
            {/*@todo language selector*/}
            {/*<div style={{right: 20, top: 20, position: 'absolute'}}><LanguageSelector/></div>*/}
            <Logo size={60}/>
            <form className={customClasses.contentSpacing} onSubmit={this.submitHandler}>
                <div>
                    {form}
                    {forgotPassword}
                </div>
                {authRedirect}
            </form>
            {errorMessage}
            <div>
                <Typography variant="body2" gutterBottom>
                    {i18n.t('NO_ACCOUNT_FORMKLUB')}
                </Typography>
                <Grid container justifyContent="center" style={{marginTop: '10px'}}>
                    <Link style={{textDecoration: 'none', width: '100%'}} to='/sign-up'>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            style={{textTransform: "none"}}>{i18n.t('BUTTON_CREATE_AN_ACCOUNT')}</Button>
                    </Link>
                </Grid>
                <Grid container justifyContent="center" style={{marginTop: '20px'}}>
                    <Typography component="div" variant="caption">{(new Date().getFullYear())} ScoreTrace All rights
                        reserved <br/> {i18n.t('VERSION')}: {process.env.REACT_APP_VERSION}</Typography>
                </Grid>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.auth(username, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirect('/')),
        onCheckAuthenticated: () => dispatch(actions.authCheckState())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(Auth);

Auth.propTypes = {
    onCheckAuthenticated: PropTypes.func.isRequired,
    onAuth: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error : PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    authNavigatePath: PropTypes.string,
}