import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from "@mui/material/Avatar";
import {Tooltip} from "@mui/material";

const MuiDataGridCellColorIndicator = (props) => {
    const {value, tooltipPlacement} = props;
    const colorArray = [['#FF0000', 'Rood'], ['#FFA500', 'Oranje'], ['#FFFF00', 'Geel'], ['#008000', 'Groen'], ['#000080', 'Blauw'], ['#00FFFF', 'Licht blauw'], ['#EE82EE', 'Roze'], ['#800080', 'Paars'], ['#FFFFFF', 'Wit'], ['#808080', 'Grijs']];
    const label = colorArray.find(color => color[0] === value)?.[1] || 'No matching color found.';

    let borderStyle;
    if (value === '#FFFFFF' || value === '#FFFF00') {
        borderStyle = {border: '1px solid rgba(224, 224, 224, 1)'}
    } else {
        borderStyle = {border: '1px solid' + value}
    }
    const combinedStyles = {
        backgroundColor: value,
        width: 20,
        height: 20,
        cursor: 'pointer',
        borderRadius: '4px',
        ...borderStyle
    };

    return (
        value ? <div style={{alignContent: 'center', height: '100%'}}>
            <Tooltip title={label} placement={tooltipPlacement || "bottom"}><Avatar sx={combinedStyles} variant={'square'}> </Avatar></Tooltip>
        </div> : null
    )
};
export default MuiDataGridCellColorIndicator;

MuiDataGridCellColorIndicator.propTypes = {
    value: PropTypes.string,
    tooltipPlacement: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top'])
};

