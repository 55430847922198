import * as React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from "@mui/material";


export const renderCheckboxCell = (paramsId, paramsValue, paramsRow, props, tabs) => {
    if(typeof paramsId === 'number'){
        let checkState = false;
        if (paramsValue) {
            checkState = true;
        }
        return  <Checkbox disabled checked={checkState} value={checkState} inputProps={{'aria-label': 'disabled checked checkbox'}}/>
    }
    return null;
}

// const MuiDataGridCellCheckbox = React.memo(function MuiDataGridCellCheckbox(props) {
//     const {value} = props;
//     let checkState = false;
//     if (value) {
//         checkState = true;
//     }
//
//     return (
//         <Checkbox disabled checked={checkState} value={checkState}
//                   inputProps={{'aria-label': 'disabled checked checkbox'}}/>
//     )
// });
//
// MuiDataGridCellCheckbox.propTypes = {
//     value: PropTypes.number.isRequired,
// };
//
// export default function RenderCellCheckbox(params) {
//     console.log(params)
//     if(typeof params.id === 'number' && params.renderType){
//         return <MuiDataGridCellCheckbox value={params.value || 0}/>
//     }
//     return null;
// }

// RenderCellCheckbox.propTypes = {
//     value: PropTypes.number,
// };
