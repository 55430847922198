import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import {FixedSizeList as List} from "react-window";
import Grid from "@mui/material/Grid2";
import BusinessIcon from '@mui/icons-material/Business';
import SearchBar from "../../../Controls/SearchBar";
import PropTypes from "prop-types";
import {fetchData} from "../../../../store/actions/dataTable";
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {getTableItems} from "../../../../store/selectors/datatableSelector";

const PREFIX = 'SearchLocationAdressDialog';

const classes = {
    rowEven: `${PREFIX}-rowEven`,
    rowOdd: `${PREFIX}-rowOdd`,
    emptyContainer: `${PREFIX}-emptyContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    [`& .${classes.rowEven}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        },
    },

    [`& .${classes.rowOdd}`]: {
        background: "#FFFFFF",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        }
    },

    [`& .${classes.emptyContainer}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '100px',
        marginTop: '20px',
    }
}));

export default function SearchLocationAddressDialog(props) {


    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState();
    const [emptylist, setEmptyList] = useState(false);
    const businessRelations = useSelector(state => getTableItems(state, 'business_relation'));

    // =====================
    // Handle Selected Company
    // =====================
    const onItemClickHandler = (selectedLocation) => {
        props.handleLocationSelected(selectedLocation)
        setFilteredData(businessRelations);
    }

    // ======================
    // Get Data
    // ======================
    const fetchClients = function () {
        dispatch(fetchData('/business-relation', 'business_relation', 0, 0, 'ASC'));
    }

    useEffect(() => {
        fetchClients()
    }, [])

    // =====================
    // Handle Search
    // =====================
    const handleChange = (searchInput) => {
        setSearchInput(searchInput);
        const result = businessRelations.filter((item) => {
            if (item.street_name === null || item.name === null) {
                return
            }
            return item.street_name.toLocaleLowerCase().includes(searchInput) || item.name.toLocaleLowerCase().includes(searchInput);
        });
        setFilteredData(result);
    }

    const clearFilter = () => {
        setFilteredData(businessRelations);
        setEmptyList(false)
    }

    // =====================
    // Check if list is empty
    // =====================
    useEffect(() => {
        if (filteredData === undefined || filteredData === null) {
            return
        }
        if (filteredData.length === 0 && searchInput.length > 0) {
            setEmptyList(true);
        } else {
            setEmptyList(false);
        }
    }, [searchInput])

    // =====================
    // Filtered array
    // =====================
    useEffect(() => {
        if (businessRelations === undefined || businessRelations === null) {
            return
        }
        if (businessRelations.length === 0) {
            setEmptyList(true);
        }
        setFilteredData(businessRelations)
    }, [businessRelations])

    // =====================
    // Company rows
    // =====================

    // const list = [...filteredData, ...data];
    const list = filteredData;
    const renderRow = ({index, style}) => (
        <div>
            <div key={list[index].id} style={style} className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                 onClick={onItemClickHandler.bind(this, list[index])}>
                <Grid container>
                    <Grid size={{xs:2, md:1}}>
                        <BusinessIcon/>
                    </Grid>
                    <Grid size={{xs:10, md:5}}>
                        <Typography variant="body2"><strong>{list[index].name}</strong></Typography>
                        <Typography
                            variant="body2">{list[index].street_name} {list[index].building_number}{list[index].building_number_addition}</Typography>
                        <Typography variant="body2">{list[index].postal_code}, {list[index].city_name}</Typography>
                    </Grid>
                    {/*<Grid xs={1}>*/}
                    {/*    <LocationOnIcon/>*/}
                    {/*</Grid>*/}
                    {/*<Grid xs={5}>*/}
                    {/*    /!*<strong>Adres:</strong><br/>*!/*/}
                    {/*    <span>{list[index].address_street_name} {list[index].address_house_no}{list[index].address_house_no_addition}</span><br/>*/}
                    {/*    <span>{list[index].address_postal_code}, {list[index].address_city_name}</span><br/>*/}
                    {/*    <br/>*/}
                    {/*</Grid>*/}
                </Grid>
            </div>
        </div>
    );

    return (
        <Root>
            <Grid container>
                <Grid size={12}>
                    <SearchBar
                        handleChange={handleChange}
                        clearFilter={clearFilter}
                    />
                </Grid>
            </Grid>
            {
                emptylist ? (
                    <div>
                        <div className={classes.emptyContainer}>
                            <span>Geen locaties gevonden</span><br/>
                        </div>
                    </div>
                ) : (
                    <List
                        height={700}
                        itemCount={list ? list.length : null}
                        itemSize={100}
                    >
                        {renderRow}
                    </List>
                )
            }
            <br/>
        </Root>
    );
}

SearchLocationAddressDialog.propTypes = {
    handleLocationSelected: PropTypes.func.isRequired,
    data: PropTypes.array
};