import Grid from "@mui/material/Grid2";
import React, {useEffect, useState} from "react";
import customClasses from "./TemplateForm.module.css";
import SearchIcon from "../../Controls/SearchIcon";
import PropTypes from "prop-types";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import SimpleDialog from "../../Dialog/SimpleDialog";
import SearchDisposerAddressDialog from "../Templates/DialogDetails/SearchDisposerAddressDialog";
import {Typography} from "@mui/material";


export default function TemplateFormDisposer(props) {
    const [openDialog, setOpenDialog] = useState(false);
    const group = "disposerparty";

    const {
        formField: {
            disposerparty_name,
            disposerpartyaddress_street_name,
            disposerpartyaddress_house_no,
            disposerpartyaddress_house_no_addition,
            disposerpartyaddress_postal_code,
            disposerpartyaddress_city_name,
        }
    } = props;


    // =====================
    // Handle selected company
    // =====================
    const handleSelected = (selected) => {
        props.setFieldValue(group + '.name', selected.name || '');
        props.setFieldValue(group + '.address_street_name', selected.street_name || selected.address_street_name || '');
        props.setFieldValue(group + '.address_house_no', selected.building_number || selected.address_house_no || '');
        props.setFieldValue(group + '.address_house_no_addition', selected.building_number_addition || selected.address_house_no_addition || '');
        props.setFieldValue(group + '.address_postal_code', selected.postal_code || selected.address_postal_code || '');
        props.setFieldValue(group + '.address_city_name', selected.city_name || selected.address_city_name || '');
        closeModal();
    };

    // =====================
    // Handle Modal
    // =====================
    const openModal = () => {
        setOpenDialog(true);
    }
    const closeModal = () => {
        setOpenDialog(false);
    };

    // ============================================
    // Load default address from project if allowed
    // ============================================
    useEffect(() => {
        if (!props.project || Object.keys(props.project).length === 0 || props.action !== 'add') {
            return;
        }
        if (!props.templateFormInitialised) {
            handleSelected(props.project.default_disposer_address)
        }
    }, [props.project])

    // =====================
    // Dialog
    // =====================
    const dialog = () => {
        if (!props.data) {
            return;
        }
        return (
            <SimpleDialog
                title="Zoek ontdoener"
                fields={props}
                size="lg"
                fullWidth={true}
                open={openDialog}
                handleClose={closeModal}>
                <SearchDisposerAddressDialog
                    companyId={props.disabled ? null : props.data.project?.company_id || null}
                    handleSelected={handleSelected}/>
            </SimpleDialog>
        )
    }

    return <React.Fragment>
        {dialog()}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDivider} container size={12}/>
        <div className={customClasses.blockIdentifier}>
            <strong>3<span className={customClasses.blockPart}>A</span></strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {disposerparty_name.label}
                <Typography sx={{
                    fontSize: 10,
                    fontStyle: 'italic',
                    textTransform: 'uppercase'
                }}>{props.values.form_type === 'B2' ? (disposerparty_name.labelExtra) : null}</Typography>
            </Grid>
            <Grid size={1}>
                <SearchIcon
                    color="rgb(113, 43, 1)"
                    handleClick={openModal}
                    disabled={props.disabled}
                />
            </Grid>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    sizeClass={customClasses.inputFull}
                    name={group + "." + disposerparty_name.name}
                    disabled={props.disabled}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                straat + nr
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + disposerpartyaddress_street_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputMedium}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + disposerpartyaddress_house_no.name}
                    type='number'
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + disposerpartyaddress_house_no_addition.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {disposerpartyaddress_postal_code.label}
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + disposerpartyaddress_postal_code.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + disposerpartyaddress_city_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputLarge}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <br/>
        <br/>
        <br/>
        <br/>
    </React.Fragment>
}

TemplateFormDisposer.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    action: PropTypes.string,
    project: PropTypes.object,
    data: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    autoFillReplaceData: PropTypes.bool,
    templateFormInitialised: PropTypes.bool,

};