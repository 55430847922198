
export function formatUTCOrLocalDate(dateString) {
    if ((dateString) && dateString.length > 10) {
        const formatter = new Intl.DateTimeFormat("nl-NL", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });

        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            // Check if the date is invalid
            return dateString; // Return the original string if it's not a valid date
        }

        // Check if the date string is already in the "day month year" format if so return original string
        if (/^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/.test(dateString) || /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}$/.test(dateString)) {
            return dateString; // Return the  as it's already in the desired format
        }

        return formatter.format(date);

    } else {
        return dateString
    }
}