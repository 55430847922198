import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import PropTypes from "prop-types";
import {TextField, Tooltip} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import i18n from "../../i18n/i18n";

const PREFIX = 'SearchBar';

const classes = {
    emptyContainer: `${PREFIX}-emptyContainer`,
    searchContainer: `${PREFIX}-searchContainer`
};

const Root = styled('div')(() => ({
    [`& .${classes.emptyContainer}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '100px',
        marginTop: '20px',
    },

    [`&.${classes.searchContainer}`]: {
        margin: '10px 0',
        width: '100%'
    }
}));

const SearchBar = (props) => {


    //Default props
    const [labelDirection, setLabelDirection] = useState('right');

    //Admin Dark mode
    let color;
    let darkStyle
    if (props.darkMode) {
        color = '#ffffff'
        darkStyle = {
            backgroundColor: "#252525",
            ".MuiInputLabel-root": {
                color: "#ffffff"
            },
            ".MuiInputBase-input": {
                color: "#ffffff"
            },
            "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                    borderColor: "white !important"
                }
            },
            "& .MuiOutlinedInput-root:hover ": {
                "& > fieldset": {
                    borderColor: "white"
                }
            },
        };
    }

    //Set values
    useEffect(() => {
        if (props.labelDirection) {
            setLabelDirection(props.labelDirection)
        }
    }, [props.labelDirection])

    return (
        <Root className={classes.searchContainer}>
            <TextField
                sx={{
                    ...darkStyle,
                }}
                // InputProps={{{root:{borderColor:` ${color}`}}}}
                id="input-with-icon-textfield"
                label={props.label || ''}
                placeholder={i18n.t('TYPE_TO_SEARCH')}
                variant="outlined"
                onChange={
                    (event) => {
                        if (event == null) {
                            props.handleChange("");
                            return;
                        }
                        props.handleChange(event.target.value);
                    }
                }
                fullWidth
                autoFocus
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{color: color}}/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip
                                    placement={labelDirection}
                                    title={i18n.t('RESET')}
                                >
                                    <ClearIcon
                                        sx={{color: color}}
                                        style={{cursor: 'pointer'}}
                                        onClick={props.clearFilter}
                                    />
                                </Tooltip>
                            </InputAdornment>)
                    }
                }}
            />
        </Root>
    );
}
export default SearchBar;

SearchBar.propTypes = {
    label: PropTypes.string,
    labelDirection: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-start', 'right-end', 'right', 'top-end', 'top-start', 'top']),
    handleChange: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    darkMode: PropTypes.bool
};